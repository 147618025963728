import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaTimes, FaBell, FaEnvelope, FaPaperPlane, FaComments, FaLightbulb, FaEye, FaExternalLinkAlt, FaRupeeSign, FaClock, FaFire } from 'react-icons/fa';
import './Landingpage.css';
import { debounce } from 'lodash';

import axios from 'axios';

// Import platform images
import leetcodeImage from './assets/lc.png';
import codeforcesImage from './assets/cf.webp';
import atcoderImage from './assets/ac.png';
import codechefImage from './assets/cc.png';

const runningContests = [
  { name: 'LeetCode Weekly Contest 342', url: 'https://leetcode.com/contest/weekly-contest-342' },
  { name: 'CodeForces Round #835 (Div. 4)', url: 'https://codeforces.com/contest/1760' },
  { name: 'AtCoder Beginner Contest 301', url: 'https://atcoder.jp/contests/abc301' },
];

const LandingPage = () => {
  const [currentContestIndex, setCurrentContestIndex] = useState(0);
  const [time, setTime] = useState(new Date());
  const [contests, setContests] = useState([]);
  const [showAuthModal, setShowAuthModal] = useState(false);

  const [showHintPopup, setShowHintPopup] = useState(false);
  const [currentHint, setCurrentHint] = useState('');
  const [showNotificationPopup, setShowNotificationPopup] = useState(false);
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
 
  const [contestStatus, setContestStatus] = useState({});
  const [showPlatformPopup, setShowPlatformPopup] = useState(false);
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);
  const [notificationEmail, setNotificationEmail] = useState('');
  const [platforms, setPlatforms] = useState([
    'LeetCode',
    'CodeForces',
    'CodeChef',
    'AtCoder'
  ]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [subscriptionConfirmation, setSubscriptionConfirmation] = useState('');
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const messages = [
    "Get refunded if the solution doesn't work",
    "Your money transfer is totally safe",
    "24/7 customer support available",
    "Join our community of expert coders"
  ];
  const [showContactPopup, setShowContactPopup] = useState(false);
  const [contactEmail, setContactEmail] = useState('');
  const [contactIssue, setContactIssue] = useState('');


  const [allContests, setAllContests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchAllData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get('/contests.php?action=get_contests');
      if (response.data.status === 'success') {
        setAllContests(response.data.data);
      } else {
        throw new Error(response.data.message || 'Failed to fetch contests');
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchAllData();
  }, [fetchAllData]);

  useEffect(() => {
    if (allContests.length > 0) {
      const groupedContests = groupContestsByName(allContests);
      setContests(groupedContests);
    }
  }, [allContests]);

  useEffect(() => {
    const timer = setInterval(() => setTime(new Date()), 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const contestTimer = setInterval(() => {
      setCurrentContestIndex((prevIndex) => (prevIndex + 1) % runningContests.length);
    }, 5000);
    return () => clearInterval(contestTimer);
  }, []);

  const debouncedFetchContests = useCallback(
    debounce(() => {
      fetchContests();
    }, 300),
    []
  );

  useEffect(() => {
    debouncedFetchContests();
    const intervalId = setInterval(debouncedFetchContests, 60000);
    return () => clearInterval(intervalId);
  }, [debouncedFetchContests]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const fetchContests = async () => {
    try {
      console.log('Fetching contests...');
      const response = await axios.get('/contests.php');
      console.log('Contests data received:', response.data);
      if (response.data.status === 'success' && Array.isArray(response.data.data)) {
        const groupedContests = groupContestsByName(response.data.data);
        console.log('Grouped contests:', groupedContests);
        setContests(groupedContests);
      } else {
        console.error('Invalid data structure received:', response.data);
      }
    } catch (error) {
      console.error('Error fetching contests:', error);
    }
  };

  const groupContestsByName = (contestsData) => {
    const grouped = {};
    contestsData.forEach(contest => {
      const platformInfo = getPlatformInfo(contest.contest_name);
      grouped[contest.contest_name] = {
        name: contest.contest_name,
        platform: platformInfo.name,
        platformImage: platformInfo.image,
        color: getRandomColor(),
        problems: contest.problems.map(problem => ({
          name: problem.name,
          solutions: Math.floor(Math.random() * 10000),
          url: '#',
          hint: problem.hint,
          price: problem.price ? parseFloat(problem.price) : 0 // Ensure price is a number
        })),
        startTime: new Date(contest.start_time),
        contest_link: contest.contest_link
      };
    });
    return Object.values(grouped);
  };

  const getPlatformInfo = (contestName) => {
    if (contestName.toLowerCase().includes('leetcode')) return { name: 'LeetCode', image: leetcodeImage };
    if (contestName.toLowerCase().includes('codeforces')) return { name: 'CodeForces', image: codeforcesImage };
    if (contestName.toLowerCase().includes('atcoder')) return { name: 'AtCoder', image: atcoderImage };
    if (contestName.toLowerCase().includes('codechef')) return { name: 'CodeChef', image: codechefImage };
    return { name: 'Other', image: codechefImage };
  };

  const getRandomColor = () => {
    return '#' + Math.floor(Math.random()*16777215).toString(16);
  };

  const handleShowHint = (hint) => {
    setCurrentHint(hint);
    setShowHintPopup(true);
  };

  const updateContestStatus = () => {
    const now = new Date();
    const updatedStatus = {};
    contests.forEach(contest => {
      if (contest.startTime) {
        const timeDiff = new Date(contest.startTime) - now;
        if (timeDiff > 0) {
          const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
          const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
          updatedStatus[contest.name] = { 
            status: 'upcoming', 
            time: `${days}d ${hours}h ${minutes}m ${seconds}s` 
          };
        } else if (timeDiff > -7200000) {
          const remainingTime = 7200000 + timeDiff;
          const hours = Math.floor(remainingTime / (1000 * 60 * 60));
          const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
          updatedStatus[contest.name] = { 
            status: 'live', 
            time: `${hours}h ${minutes}m ${seconds}s` 
          };
        } else {
          updatedStatus[contest.name] = { status: 'ended', time: 'Ended' };
        }
      } else {
        updatedStatus[contest.name] = { status: 'unknown', time: 'Unknown' };
      }
    });
    setContestStatus(updatedStatus);
  };

  useEffect(() => {
    updateContestStatus();
    const statusTimer = setInterval(updateContestStatus, 1000);
    return () => clearInterval(statusTimer);
  }, [contests]);

  const sortedContests = [...contests].sort((a, b) => {
    const statusA = contestStatus[a.name];
    const statusB = contestStatus[b.name];
    if (statusA && statusB) {
      if (statusA.status === 'live' && statusB.status !== 'live') return -1;
      if (statusB.status === 'live' && statusA.status !== 'live') return 1;
      if (statusA.status === 'upcoming' && statusB.status === 'upcoming') {
        return new Date(a.startTime) - new Date(b.startTime);
      }
    }
    return 0;
  });

  const handleNotificationClick = () => {
    setShowPlatformPopup(true);
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('/subscribe.php', {
        email,
        contestName: contests[currentContestIndex].name
      });
      setShowNotificationPopup(false);
      setEmail('');
      alert('You have successfully subscribed for notifications!');
    } catch (error) {
      console.error('Error subscribing:', error);
      alert('There was an error subscribing. Please try again.');
    }
  };

  const handleNotificationSubmit = async () => {
    if (selectedPlatforms.length === 0 || !notificationEmail) {
      alert('Please select at least one platform and enter your email.');
      return;
    }

    try {
      const response = await axios.post('/subscribe.php', {
        email: notificationEmail,
        platforms: selectedPlatforms
      });

      if (response.data.status === 'success') {
        setSubscriptionConfirmation(`You will now receive notifications before contests from: ${selectedPlatforms.join(', ')}`);
        setTimeout(() => {
          setShowPlatformPopup(false);
          setSubscriptionConfirmation('');
          setSelectedPlatforms([]);
          setNotificationEmail('');
        }, 5000);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      console.error('Error subscribing:', error);
      setSubscriptionConfirmation('There was an error subscribing. Please try again.');
    }
  };

  const handleContactSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/submit_payment.php', {
        email: contactEmail,
        issue: contactIssue,
        type: 'contact'
      });

      if (response.data.status === 'success') {
        alert('Your message has been sent. We will get back to you soon.');
        setShowContactPopup(false);
        setContactEmail('');
        setContactIssue('');
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      console.error('Error sending contact form:', error);
      alert('There was an error sending your message. Please try again.');
    }
  };

  const handleTalkToUs = () => {
    setShowContactPopup(true);
  };

  const handleSolutionClick = (problemName) => {
    navigate(`/problem-solutions/${encodeURIComponent(problemName)}`);
  };

  const renderContests = () => {
    return sortedContests.map((contest) => {
      // Skip rendering live contests in the old design
      if (contestStatus[contest.name]?.status === 'live') {
        return null;
      }

      return (
        <div key={contest.name} className="platform-card glass-panel">
          <div className="platform-header">
            <h2>{contest.name}</h2>
            <div className="platform-actions">
              {contestStatus[contest.name]?.status === 'upcoming' && (
                <button className="notify-me-btn glass-btn" onClick={() => handleNotificationClick(contest.name)}>
                  <FaBell /> Get Notified
                </button>
              )}
              {contest.contest_link && (
                <a href={contest.contest_link} target="_blank" rel="noopener noreferrer" className="notify-me-btn glass-btn">
                  <FaExternalLinkAlt /> Go to Contest
                </a>
              )}
            </div>
          </div>
          <div className="platform-image-container">
            <img src={contest.platformImage} alt={contest.platform} className="platform-image" />
          </div>
          <p className="contest-status" data-status={contestStatus[contest.name]?.status || 'unknown'}>
            {contestStatus[contest.name]?.status === 'upcoming' && 'Starts in: '}
            <span>
              {contestStatus[contest.name]?.time || 'Status unknown'}
            </span>
          </p>
        </div>
      );
    }).filter(Boolean); // Filter out null values (live contests)
  };

  const renderPrice = (price) => {
    if (typeof price === 'number') {
      return price.toFixed(2);
    } else if (typeof price === 'string') {
      const numPrice = parseFloat(price);
      return isNaN(numPrice) ? price : numPrice.toFixed(2);
    } else {
      return 'N/A';
    }
  };

  const renderRunningContest = () => {
    const runningContest = contests.find(contest => contestStatus[contest.name]?.status === 'live');
    
    if (!runningContest) return null;

    return (
      <div className="running-contest-container">
        <div className="running-contest-header">
          <h2><FaFire className="fire-icon" /> Live Contest</h2>
          <span className="contest-name">{runningContest.name}</span>
          <span className="contest-timer">
            <FaClock /> {contestStatus[runningContest.name]?.time}
          </span>
        </div>
        <div className="running-contest-problems">
          {runningContest.problems.map((problem, index) => (
            <div key={index} className="running-problem-item">
              <div className="problem-info">
                <span className="problem-letter">{String.fromCharCode(65 + index)}</span>
                <span className="problem-name">{problem.name}</span>
                <span className="problem-price"><FaRupeeSign /> {renderPrice(problem.price)}</span>
              </div>
              <div className="problem-actions">
                <button 
                  className="hint-btn glass-btn"
                  onClick={() => handleShowHint(problem.hint)}
                >
                  <FaLightbulb /> Hint
                </button>
                <Link 
                  to={`/problem-solutions/${encodeURIComponent(problem.name)}/${encodeURIComponent(problem.price)}`} 
                  className="solution-btn glass-btn"
                >
                  <FaEye /> View Solution
                </Link>
              </div>
            </div>
          ))}
        </div>
        {runningContest.contest_link && (
          <a href={runningContest.contest_link} target="_blank" rel="noopener noreferrer" className="contest-link-btn">
            <FaExternalLinkAlt /> Go to Contest
          </a>
        )}
      </div>
    );
  };

  useEffect(() => {
    const easeInOutQuad = (t) => t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;

    const smoothScrollWithEasing = (target) => {
      // Check if the target is just '#' or '#/', which are invalid selectors
      if (target === '#' || target === '#/') {
        // Scroll to the top of the page instead
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
        return;
      }

      const element = document.querySelector(target);
      if (element) {
        const startPosition = window.pageYOffset;
        const targetPosition = element.getBoundingClientRect().top + startPosition;
        const distance = targetPosition - startPosition;
        const duration = 1000; // ms
        let start = null;

        const step = (timestamp) => {
          if (!start) start = timestamp;
          const progress = timestamp - start;
          const percentage = Math.min(progress / duration, 1);
          const ease = easeInOutQuad(percentage);
          
          window.scrollTo(0, startPosition + distance * ease);

          if (progress < duration) {
            window.requestAnimationFrame(step);
          }
        };

        window.requestAnimationFrame(step);
      }
    };

    // Add smooth scroll to all anchor links
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', function (e) {
        const href = this.getAttribute('href');
        // Check if the href is just '#' or starts with '#/'
        if (href === '#' || href.startsWith('#/')) {
          // Let the router handle the navigation
          return;
        }
        e.preventDefault();
        smoothScrollWithEasing(href);
      });
    });

    // Optional: Add parallax effect to background elements
    const parallaxEffect = () => {
      const parallaxElements = document.querySelectorAll('.parallax-bg');
      parallaxElements.forEach(element => {
        const scrollPosition = window.pageYOffset;
        element.style.backgroundPositionY = `${scrollPosition * 0.5}px`;
      });
    };

    window.addEventListener('scroll', parallaxEffect);

    // Cleanup event listeners
    return () => {
      document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.removeEventListener('click', function (e) {
          const href = this.getAttribute('href');
          if (href === '#' || href.startsWith('#/')) {
            return;
          }
          e.preventDefault();
          smoothScrollWithEasing(href);
        });
      });
      window.removeEventListener('scroll', parallaxEffect);
    };
  }, []);

  return (
    <div className="coding-contest-landing">
      {loading && <div>Loading...</div>}
      {error && <div>Error: {error}</div>}
      {!loading && !error && (
        <>
          <div className="notification-bar glass-panel">
            <div className="notification-content">
              <p className="notification-text">Get notified in the morning whenever there is a contest from your selected sites</p>
              <button className="notify-me-btn glass-btn" onClick={handleNotificationClick}>
                <FaBell /> Get Notified
              </button>
            </div>
            
            <div className="creator-info">
              <p className="creator-message">
                Created by a <span className="highlight">1900-rated Codeforces</span> and <span className="highlight">6-star CodeChef</span> coder, 
                for students who just need <span className="highlight-pulse">a little push</span>.
              </p>
              <p className="cphelper-link">
                Please visit <a href="https://cphelper.online" target="_blank" rel="noopener noreferrer" className="highlight">CPHelper</a> again!
              </p>
              
            </div>
          </div>

          <div className="running-contests-bar glass-panel">
            <div className="message-container">
              {messages.map((message, index) => (
                <div
                  key={index}
                  className={`message ${index === currentMessageIndex ? 'active' : ''}`}
                >
                  {message}
                </div>
              ))}
            </div>
            
          </div>

          {renderRunningContest()}

          <main id="contests" className="platform-grid">
            {sortedContests.length > 0 ? (
              renderContests()
            ) : (
              <div className="no-contests">
                <p>No contests available at the moment. Please check back later.</p>
              </div>
            )}
          </main>

          <section id="features" className="features glass-panel">
            <h2>Why Choose CPHelper?</h2>
            <div className="feature-list">
              <div className="feature-item">
                <h3>Real-time Updates</h3>
                <p>Get instant notifications about new contests and results.</p>
              </div>
              <div className="feature-item">
                <h3>Multi-platform Support</h3>
                <p>Access contests from various coding platforms in one place.</p>
              </div>
              <div className="feature-item">
                <h3>Performance Tracking</h3>
                <p>Monitor your progress and improve your skills over time.</p>
              </div>
            </div>
          </section>
          

          <footer id="contact" className="glass-panel">
           
            <p>&copy; 2023 CPHelper. All rights reserved.</p>
            
          </footer>

          {showAuthModal && (
            <div className="auth-modal">
              <div className="auth-modal-content glass-panel">
                <button className="close-modal" onClick={() => setShowAuthModal(false)}>
                  <FaTimes />
                </button>
                
               
              </div>
            </div>
          )}

          {showHintPopup && (
            <div className="hint-popup">
              <div className="hint-popup-content glass-panel">
                <button className="close-modal" onClick={() => setShowHintPopup(false)}>
                  <FaTimes />
                </button>
                <h3>Hint</h3>
                <p>{currentHint}</p>
              </div>
            </div>
          )}

          {showNotificationPopup && (
            <div className="notification-popup">
              <div className="notification-popup-content glass-panel">
                <button className="close-modal" onClick={() => setShowNotificationPopup(false)}>
                  <FaTimes />
                </button>
                <h3>Get Notified</h3>
                <form onSubmit={handleEmailSubmit}>
                  <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <button type="submit">Subscribe</button>
                </form>
              </div>
            </div>
          )}

          {showPlatformPopup && (
            <div className="platform-popup">
              <div className="platform-popup-content glass-panel">
                <button className="close-modal" onClick={() => setShowPlatformPopup(false)}>
                  <FaTimes />
                </button>
                <h3>Get Notified for Contests</h3>
                {subscriptionConfirmation ? (
                  <div className="subscription-confirmation">
                    <p>{subscriptionConfirmation}</p>
                  </div>
                ) : (
                  <>
                    <div className="platform-list">
                      {platforms.map((platform) => (
                        <label key={platform}>
                          <input
                            type="checkbox"
                            value={platform}
                            checked={selectedPlatforms.includes(platform)}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setSelectedPlatforms([...selectedPlatforms, platform]);
                              } else {
                                setSelectedPlatforms(selectedPlatforms.filter(p => p !== platform));
                              }
                            }}
                          />
                          {platform}
                        </label>
                      ))}
                    </div>
                    <input
                      type="email"
                      placeholder="Enter your email"
                      value={notificationEmail}
                      onChange={(e) => setNotificationEmail(e.target.value)}
                      required
                    />
                    <button className="sub" onClick={handleNotificationSubmit}>Subscribe</button>
                  </>
                )}
              </div>
            </div>
          )}

          {showConfirmation && (
            <div className="confirmation-popup glass-panel">
              <p>
                You will be notified on the day of contests from the following sites: 
                {selectedPlatforms.join(', ')}
              </p>
            </div>
          )}

          <button className="sticky-contact-btn" onClick={handleTalkToUs} aria-label="Talk to Us">
            <FaComments style={{ marginRight: '8px' }} /> Talk to Us
          </button>

          {showContactPopup && (
            <div className="contact-popup-overlay">
              <div className="contact-popup-content">
                <button className="close-modal" onClick={() => setShowContactPopup(false)}>
                  <FaTimes />
                </button>
                <h3>📬 Contact Us</h3>
                <form onSubmit={handleContactSubmit}>
                  <div className="input-group">
                    <FaEnvelope className="input-icon" />
                    <input
                      type="email"
                      placeholder="Your Email"
                      value={contactEmail}
                      onChange={(e) => setContactEmail(e.target.value)}
                      required
                    />
                  </div>
                  <div className="input-group">
                    <FaPaperPlane className="input-icon" />
                    <textarea
                      placeholder="Describe your issue"
                      value={contactIssue}
                      onChange={(e) => setContactIssue(e.target.value)}
                      required
                    ></textarea>
                  </div>
                  <button type="submit" className="submit-btn">
                    <FaPaperPlane /> Send Message
                  </button>
                </form>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default LandingPage;